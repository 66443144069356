import request from '@/utils/request'
// import axios from 'axios'

// 查询签到配置列表
export function uploadCos(data) {
  return request({
    url: '/common/local/file/uploadCos',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data
  })
}